import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    getReturnMethods,
    resetReturnMethods
} from '@/redux/api/returnMethodsApiSlice'
import { useNavigate } from 'react-router-dom'
import {
    PkgInfrastructureRestGetReturnMethodsRequest as RestGetReturnMethodsRequest,
    GithubComItsreverDomainModelsPkgModelsAddress as ModelsAddress,
    ApiExchangeSelection
} from '@itsrever/returns-api-types'
import { ReturnMethod, setReturnMethod } from '@/redux/return/returnSlice'
import { toast } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'
import { usePages } from '@/hooks'
import { AxiosError } from 'axios'
import { resetCalculate, resetProcess } from '@/redux/api/returnsApiSlice'

export function useReturnMethods(onSuccess?: () => void) {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pages } = usePages()

    const returnMethodsCall = useAppSelector(
        (store) => store.returnMethodsApi.returnMethods
    )

    const returnMethods = returnMethodsCall.response.return_methods ?? []
    const loadingReturnMethods = returnMethodsCall.loading
    const errorReturnMethods = returnMethodsCall.error

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceID = settings.ecommerce_id

    const orderInfo = useAppSelector((store) => store.order)
    const returnedItems = useAppSelector(
        (store) => store.return.returnedItems
    ).map((p) => {
        return p.item
    })

    const checkout_id = useAppSelector(
        (store) => store.returnsApi.order.response.checkout_id
    )
    const refundPaymentMethod = useAppSelector(
        (store) => store.return.refundPaymentMethod
    )
    const exchangeSelections = useAppSelector(
        (store) => store.return.newItems
    ).map((item) => {
        const exchangeItem: ApiExchangeSelection = {
            line_item_platform_id: item.returnedItemId,
            quantity: item.quantity,
            variant_id: item.productVariant.variantId,
            signed_product_raw: item.signedProductRaw,
            collection_ids: item.collectionIds
        }
        return exchangeItem
    })

    const keepYourItemSelected = useAppSelector(
        (store) => store.return.keepYourItem
    )

    function callReturnMethods(returnAddress: ModelsAddress) {
        if (
            ecommerceID &&
            checkout_id &&
            returnAddress &&
            returnedItems.length > 0
        ) {
            const request: RestGetReturnMethodsRequest = {
                checkout_id,
                return_address: returnAddress,
                items_to_return: returnedItems,
                refund_method: refundPaymentMethod.method,
                exchange_selections: exchangeSelections,
                keep_your_item_selected: keepYourItemSelected
            }
            dispatch(getReturnMethods({ ecommerceID, request }))
        }
    }

    function handleSuccess() {
        dispatch(resetReturnMethods())

        dispatch(resetCalculate())
        dispatch(resetProcess())

        if (returnMethods.length > 0)
            dispatch(
                setReturnMethod({
                    returnMethod: returnMethods[0].name as ReturnMethod,
                    userSelectedCarrier: returnMethods[0].carrier ?? '',
                    userSelectedProvider: returnMethods[0].provider ?? ''
                })
            )

        if (onSuccess) onSuccess()
    }

    function handleReject() {
        dispatch(resetReturnMethods())
        console.warn(errorReturnMethods)
        const error = errorReturnMethods as AxiosError

        if (error.response?.status === 401 || error.response?.status === 419) {
            toast({
                text: t('toast_errors.error_419'),
                variant: 'error'
            })
            navigate(
                `${pages.Landing}?orderNumber=${encodeURIComponent(
                    orderInfo.orderNumber || ''
                )}&email=${encodeURIComponent(orderInfo.email || '')}`
            )
            return
        }
        navigate(pages.Error)
    }

    useEffect(() => {
        if (loadingReturnMethods === 'succeeded') {
            handleSuccess()
            return
        }

        if (loadingReturnMethods === 'failed') {
            handleReject()
            return
        }
    }, [loadingReturnMethods])

    return {
        callReturnMethods,
        loadingReturnMethodsState: loadingReturnMethods,
        loadingReturnMethods: loadingReturnMethods === 'pending'
    }
}
