import ReturnProductSummary from '../ReturnProductSummary'
import { useTheme } from '@itsrever/design-system'
import { useAppSelector } from '@/redux/hooks'
import { formatPrice } from '@/utils'
import styled from 'styled-components'
import { ExchangedItem } from '@/components/ExchangedItem.tsx'
import {
    GithubComItsreverReturnsApiPkgDomainSummaryCost as SummaryCost,
    GithubComItsreverReturnsApiPkgDomainSummaryExchangeItem as SummaryExchangeItem,
    GithubComItsreverReturnsApiPkgDomainSummaryRetItem as SummaryRetItem,
    PkgInfrastructureRestRefundMethod as RefundMethod
} from '@itsrever/returns-api-types'
import {
    ReturnsCalculatedSummaryCost,
    ReturnsCalculatedSummaryExchangeItem,
    ReturnsCalculatedSummaryReturnedItem
} from '@itsrever/rever-api'
import { GithubComItsreverDomainModelsPkgModelsReturnLineItem as ModelsReturnLineItem } from '@itsrever/returns-api-types'
import { useTranslate } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { LineItemSubtypes } from '@/redux/return/returnSlice'
import KYIInfoBanner from '@/components/KYIInfoBanner/KYIInfoBanner'

interface SummaryProps {
    returnedItems: SummaryRetItem[] | ReturnsCalculatedSummaryReturnedItem[]
    exchangeItems:
        | SummaryExchangeItem[]
        | ReturnsCalculatedSummaryExchangeItem[]
    costs: SummaryCost[] | ReturnsCalculatedSummaryCost[]
    totalRefundAmount: number
    totalPaymentAmount?: number
    RDVSurplus?: ModelsReturnLineItem | undefined
    isKeepYourItem?: boolean
    refundMethod?: RefundMethod
    serviceFee?: ModelsReturnLineItem | undefined
    originalPromotionBroken?: ModelsReturnLineItem | undefined
    exchangePromotion?: ModelsReturnLineItem | undefined
}

export const Summary: React.FC<SummaryProps> = ({
    returnedItems,
    exchangeItems,
    costs,
    totalRefundAmount,
    totalPaymentAmount,
    RDVSurplus,
    isKeepYourItem,
    refundMethod,
    serviceFee,
    originalPromotionBroken,
    exchangePromotion
}) => {
    const theme = useTheme()
    const { translate } = useTranslate()
    const { t } = useTranslation()

    const clientSecret = useAppSelector(
        (store) => store.returnsApi.process.response.payment_session_id
    )
    const orderMoneyFormat =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        ) ?? {}

    const returnedItemsQuantity = returnedItems.reduce(
        (acc, item) => acc + (item.quantity ?? 0),
        0
    )
    const exchangedItemsQuantity = exchangeItems.reduce(
        (acc, item) => acc + (item.quantity ?? 0),
        0
    )
    const isOnlyExchanges = returnedItemsQuantity === exchangedItemsQuantity

    const subTotal = () => {
        let subTotal = 0
        returnedItems.forEach((item) => {
            subTotal += Number(item.total ?? 0) ?? 0
        })
        exchangeItems.forEach((item) => {
            subTotal += Number(item.total ?? 0) ?? 0
        })
        return subTotal
    }

    function costColor(total: number) {
        if (total >= 0) return theme.colors.common.black
        return theme.colors.error.main
    }

    function totalColor(total: number) {
        if (total >= 0) return theme.colors.success.main
        return theme.colors.grey[0]
    }

    const onlyDefectiveOrWrong = () => {
        if (
            costs.some((cost) => cost.subtype === LineItemSubtypes.ShippingCost)
        )
            return returnedItems.every((item) => {
                return (
                    item.return_reason === 'DEFECTIVE' ||
                    item.return_reason === 'WRONG_ITEM'
                )
            })
        return false
    }

    const isPayForLogistics =
        totalRefundAmount > 0 && (totalPaymentAmount ?? 0) > 0

    return (
        <>
            <HiddenSummaryPart show={clientSecret ? 'true' : 'false'}>
                <div className="border-b border-solid border-grey-3">
                    <p
                        className="mb-4"
                        dangerouslySetInnerHTML={{
                            __html: translate('summary_returned_products')
                        }}
                    />
                    {returnedItems.length > 0
                        ? returnedItems.map((li, i) => {
                              return (
                                  <ReturnProductSummary key={i} lineItem={li} />
                              )
                          })
                        : null}
                </div>
                {exchangeItems.length > 0 ? (
                    <div className="border-b border-solid border-grey-3">
                        <p
                            className="my-2"
                            dangerouslySetInnerHTML={{
                                __html: translate('summary_new_products')
                            }}
                        />

                        {exchangeItems.map((li, i) => {
                            return <ExchangedItem key={i} exchangedItem={li} />
                        })}
                    </div>
                ) : null}
            </HiddenSummaryPart>
            <div>
                <>
                    {isPayForLogistics ? (
                        <SummaryLine className="mb-4 mt-6">
                            <p>
                                <b>{t('confirmation.amount_to_receive')}: </b>
                            </p>
                            <PriceText
                                style={{
                                    color: totalColor(totalRefundAmount)
                                }}
                            >
                                <b>
                                    {formatPrice(
                                        totalRefundAmount,
                                        orderMoneyFormat
                                    )}
                                </b>
                            </PriceText>
                        </SummaryLine>
                    ) : (
                        !isKeepYourItem && (
                            <SummaryLine className="mt-6">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate('summary_subtotal')
                                    }}
                                />
                                <PriceText>
                                    {formatPrice(subTotal(), orderMoneyFormat)}
                                </PriceText>
                            </SummaryLine>
                        )
                    )}
                    {RDVSurplus ? (
                        <SummaryLine>
                            <p>
                                {t('RDV_surplus.line_item_text')}{' '}
                                {refundMethod &&
                                    translate(
                                        `refund_card_method${refundMethod?.method}_title`
                                    )}
                                :
                            </p>
                            <PriceText
                                style={{
                                    color: theme.colors.success.main
                                }}
                            >
                                {formatPrice(
                                    Number(RDVSurplus.total),
                                    orderMoneyFormat
                                )}
                            </PriceText>
                        </SummaryLine>
                    ) : null}

                    {serviceFee ? (
                        <SummaryLine>
                            <p>{t('service_fees.summary_text')}</p>
                            <PriceText
                                style={{
                                    color: theme.colors.error.main
                                }}
                            >
                                {formatPrice(
                                    Number(serviceFee.total),
                                    orderMoneyFormat
                                )}
                            </PriceText>
                        </SummaryLine>
                    ) : null}

                    {originalPromotionBroken ? (
                        <SummaryLine>
                            <p>{t('cost_type.ORIGINAL_PROMOTION_BROKEN')}</p>
                            <PriceText
                                style={{
                                    color: theme.colors.error.main
                                }}
                            >
                                {formatPrice(
                                    Number(originalPromotionBroken.total),
                                    orderMoneyFormat
                                )}
                            </PriceText>
                        </SummaryLine>
                    ) : null}
                    {exchangePromotion ? (
                        <SummaryLine className="flex items-center py-2">
                            <div className="flex flex-col gap-1 ">
                                <p>{t('cost_type.EXCHANGE_PROMOTION')}</p>
                                <p className="w-fit rounded bg-neutral-100 px-1 text-xs">
                                    {exchangePromotion?.name}
                                </p>
                            </div>
                            <PriceText
                                style={{
                                    color: theme.colors.success.main
                                }}
                            >
                                {formatPrice(
                                    Number(exchangePromotion.total),
                                    orderMoneyFormat
                                )}
                            </PriceText>
                        </SummaryLine>
                    ) : null}

                    {!isKeepYourItem && costs.length > 0
                        ? costs.map((cost, i) => {
                              if (cost.total !== undefined) {
                                  return (
                                      <SummaryLine key={i}>
                                          <p>
                                              {cost.name
                                                  ? cost.name
                                                  : t(
                                                        `cost_type.${cost.subtype}`
                                                    ) ?? ''}
                                          </p>

                                          <PriceText
                                              style={{
                                                  color: costColor(
                                                      Number(cost.total)
                                                  )
                                              }}
                                          >
                                              {formatPrice(
                                                  Number(cost.total),
                                                  orderMoneyFormat
                                              )}
                                          </PriceText>
                                      </SummaryLine>
                                  )
                              }
                          })
                        : null}
                </>
                {isPayForLogistics ? null : (
                    <SummaryLine>
                        <p>
                            {isKeepYourItem && !isOnlyExchanges ? (
                                <b>
                                    {translate('kyi_summary_line_copy')}{' '}
                                    <span className="ml-1 rounded bg-grey-5 px-1 font-normal">
                                        {
                                            refundMethod?.keep_your_item
                                                ?.percentage
                                        }
                                        %
                                    </span>
                                </b>
                            ) : (
                                <b
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            'summary_final_balance'
                                        )
                                    }}
                                />
                            )}
                        </p>

                        <p
                            className="shrink-0"
                            style={{
                                color: totalColor(
                                    totalRefundAmount -
                                        (totalPaymentAmount ?? 0)
                                )
                            }}
                        >
                            <b>
                                {formatPrice(
                                    totalRefundAmount -
                                        (totalPaymentAmount ?? 0),
                                    orderMoneyFormat
                                )}
                            </b>
                        </p>
                    </SummaryLine>
                )}
                {onlyDefectiveOrWrong() ? (
                    <p className="mt-2 text-sm text-red-400">
                        {t('confirmation.only_defective_or_wrong')}
                    </p>
                ) : null}
            </div>
            {isKeepYourItem && (
                <div className="mt-4 border-t border-solid border-grey-3 pt-4 text-grey-1">
                    <KYIInfoBanner />
                </div>
            )}
        </>
    )
}

export default Summary

const HiddenSummaryPart = styled.div<{ show: string }>`
    margin-bottom: 1rem;
    @media (max-width: 899px) {
        display: ${(p) => (p.show === 'true' ? 'none' : '')};
    }
`

const SummaryLine = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
`

const PriceText = styled.p`
    white-space: nowrap;
`
